.report {
  h6 {
    color: #042e60;
    font-weight: 400;
  }
  .table {
    margin-bottom: 25px;
    thead {
      background-color: #042e60;
      color: #fff;
    }
  }
  .table-bordered th,
  .table-bordered td {
    padding: 5px !important;
  }
  .lastTrBlue {
    background-color: #042e60 !important;
    color: #e19406;
    color: #fff !important;
    td {
      color: #fff !important;
    }
  }
  .lastTrOrange {
    background-color: #e19406 !important;
    color: #042e60;
    color: #fff !important;
    td {
      color: #fff !important;
    }
  }
  .border-bottom-blue {
    border-bottom: 2px solid #042e60;
    border-bottom-width: 99%;
  }
}
