// Import Custom SB Admin 2 Variables (Overrides Default Bootstrap Variables)
@import "variables.scss";

// Import Bootstrap
@import "~bootstrap/scss/bootstrap";

// Import Custom SB Admin 2 Mixins and Components
@import "mixins.scss";
@import "global.scss";
@import "utilities.scss";

// Custom Components
@import "dropdowns.scss";
@import "stripe.scss";
@import "navs.scss";
@import "buttons.scss";
@import "cards.scss";
@import "charts.scss";
@import "login.scss";
@import "error.scss";
@import "footer.scss";
@import "report.scss";

.block-line {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  svg {
    margin-right: 15px;
  }
  p {
    margin: 0;
  }
}
.f-10 {
  font-size: 10px;
}
.f-12 {
  font-size: 12px;
}

.lighter {
  font-weight: 400;
  text-transform: capitalize;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.middle {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.btn-change {
  font-size: 11px;
  right: 5px;
  margin-left: 2px;
  margin-right: 2px;
  color: #fff;
  svg {
    padding-right: 3px;
  }
}

.table-bordered th,
.table-bordered td {
  padding: 1rem 0.25rem;
}

.toast-confirm {
  max-width: 220px;
  margin: auto;
}

.my-100 {
  margin-top: 100px;
  margin-bottom: 100px;
}

.Toastify__toast--success {
  background-color: #021e3f;
  color: #fff;
}

.Toastify__toast--warning {
  background-color: #be3d30;
  color: #fff;
}

.card-error {
  margin-top: 5px;
  font-size: 10px;
  color: #fa755a;
}

.pricing-table .block-heading {
  padding-top: 50px;
  margin-bottom: 40px;
  text-align: center;
}

.pricing-table .block-heading p {
  text-align: center;
  max-width: 420px;
  margin: auto;
  opacity: 0.7;
}

.pricing-table .heading {
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.pricing-table .item {
  background-color: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.075);
  border-top: 2px solid #021e3f;
  padding: 30px;
  overflow: hidden;
  position: relative;
}

.pricing-table .col-md-5:not(:last-child) .item {
  margin-bottom: 30px;
}

.pricing-table .item button {
  font-weight: 600;
}

.pricing-table .ribbon {
  width: 160px;
  height: 32px;
  font-size: 12px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  box-shadow: 0px 2px 3px rgba(136, 136, 136, 0.25);
  background: #4dbe3b;
  transform: rotate(45deg);
  position: absolute;
  right: -42px;
  top: 20px;
  padding-top: 7px;
}

.pricing-table .item p {
  text-align: center;
  margin-top: 20px;
  opacity: 0.7;
}

.pricing-table .features .feature {
  font-weight: 600;
}

.pricing-table .features h4 {
  text-align: center;
  font-size: 18px;
  padding: 5px;
}

.pricing-table .price h4 {
  margin: 15px 0;
  font-size: 45px;
  text-align: center;
  color: #2288f9;
}

.pricing-table .buy-now button {
  text-align: center;
  margin: auto;
  font-weight: 600;
  padding: 9px 0;
}

.form-control {
  margin-bottom: 10px;
}

.btn {
  color: #fff;
}

.btn-outline-primary {
  color: #021e3f;
}
.btn-outline-primary:hover {
  color: #fff;
}

.all-page-container {
  height: 100%;
  max-height: 500px;
  overflow: auto;
}
